<template>
  <v-date-picker :value="value"
                 :landscape="$vuetify.breakpoint.mdAndUp"
                 full-width
                 :disabled="isReadSurvey || options.readonly"
                 @input="onUpdateValue"
  />
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'Date',

  mixins: [questionMixins],
}
</script>
